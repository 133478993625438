<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Termin Komisi" :active="isActive" v-on:update:active="emitModalIsActive">
      <vs-table :data="termins" stripe class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">Termin</vs-th>
          <vs-th class="whitespace-no-wrap">Ketentuan</vs-th>
          <vs-th class="whitespace-no-wrap">Komisi Cair</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm whitespace-no-wrap">
            <vs-td>{{ item.no_urut }}</vs-td>
            <vs-td>
              <div class="flex items-center">
                <span>{{ item.syarat_cair }}</span>
                <template v-if="item.syarat_cair_persen">
                  <feather-icon icon="ArrowRightIcon" class="mx-2" svg-classes="h-4 w-4"/>{{ item.syarat_cair_persen }}%
                </template>
              </div>
            </vs-td>
            <vs-td>
              <div class="flex items-center">
                <span>{{ item.komisi_cair }}</span>
                <template v-if="item.komisi_cair_persen">
                  <feather-icon icon="ArrowRightIcon" class="mx-2" svg-classes="h-4 w-4"/>{{ item.komisi_cair_persen }}%
                </template>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'TerminKomisi',
  props: {
    isActive: { required: true, type: Boolean },
    termins: { required: true, type: Array }
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
