import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/kontrak'

export default {
  get (idKontrak, params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idKontrak}/komisi${queryParams}`)
  }
}
